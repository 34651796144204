// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("/buddy/minj-tour-n-travel/node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-itinerary-js": () => import("/buddy/minj-tour-n-travel/src/templates/itinerary.js" /* webpackChunkName: "component---src-templates-itinerary-js" */),
  "component---src-templates-category-js": () => import("/buddy/minj-tour-n-travel/src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-tours-js": () => import("/buddy/minj-tour-n-travel/src/templates/tours.js" /* webpackChunkName: "component---src-templates-tours-js" */),
  "component---src-pages-404-js": () => import("/buddy/minj-tour-n-travel/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/buddy/minj-tour-n-travel/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-packages-js": () => import("/buddy/minj-tour-n-travel/src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-packages-domestic-js": () => import("/buddy/minj-tour-n-travel/src/pages/packages/domestic.js" /* webpackChunkName: "component---src-pages-packages-domestic-js" */),
  "component---src-pages-packages-international-js": () => import("/buddy/minj-tour-n-travel/src/pages/packages/international.js" /* webpackChunkName: "component---src-pages-packages-international-js" */)
}

